<template>
    <div class="container">
        <!-- 背景图片裁剪弹窗 -->
        <LiefengModal @input="cropperCancel" width="600px" height="500px" :value="cropperStatus">
            <template v-slot:contentarea>
                <!-- <Mymodel
            :fullscreen="false"
            :value="toggle1"
            :toggle="true"
            :textObj="textObj"
            title="文本预览"
            :toggle-fn2="toggleFn2"
        >
        </Mymodel> -->
                <div class="cropper-content" v-if="cropperStatus">
                    <div class="cropper" style="text-align: center">
                        <vueCropper
                            ref="cropper"
                            :img="option.img"
                            :outputSize="option.size"
                            :outputType="option.outputType"
                            :info="true"
                            :full="option.full"
                            :canMove="option.canMove"
                            :canMoveBox="option.canMoveBox"
                            :original="option.original"
                            :autoCrop="option.autoCrop"
                            :fixed="option.fixed"
                            :fixedNumber="option.fixedNumber"
                            :centerBox="option.centerBox"
                            :infoTrue="option.infoTrue"
                            :fixedBox="option.fixedBox"
                        ></vueCropper>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="cropperCancel(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="cropperFinish">裁剪</Button>
            </template>
        </LiefengModal>

        <LiefengModal @input="changeImgStatus" title="模板图片" width="868px" height="600px" :value="imgStatus">
            <template v-slot:contentarea>
                <div class="imgbox">
                    <div @click="selectImg(item.filePath)" class="imgitem" v-for="item in imgTemplateList" :key="item.index">
                        <img :src="item.filePath" alt="" />
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right: 10px" @click="changeImgStatus(false)">取消</Button>
            </template>
        </LiefengModal>
        <div class="menu">
            <Menu theme="light" :active-name="showMenu" @on-select="selectMenu">
                <MenuItem name="baseInfo">
                    <Icon type="ios-information-circle" />
                    基本信息
                </MenuItem>
                <!-- <MenuItem name="moreSetting">
          <Icon type="md-chatbubbles" />
          详细内容
        </MenuItem> -->
            </Menu>
        </div>
        <div class="content">
            <Form :model="formData" :label-colon="true" :label-width="120" v-show="showMenu == 'baseInfo'" :rules="formDataValidate" ref="formData">
                <FormItem label="信息名称" prop="title" id="formtitle">
                    <Input style="width: 300px" :disabled="Type == 'check' || Type == 'examine' || Type == 'send'" v-model.trim="formData.title" :maxlength="100" placeholder="请填写信息名称"></Input>
                </FormItem>

                <FormItem label="封面图片" id="formphoto">
                    <span slot="label" class="validate">封面图片:</span>
                    <img ref="image" :src="formData.photo" alt="" width="200" style="display: block; margin-bottom: 10px" />
                    <Progress v-if="file.showProgress" :percent="file.percentage" :stroke-width="5"></Progress>
                    <Upload
                        ref="imgUpload"
                        :before-upload="beforeUpload"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        style="display: inline-block"
                        :on-format-error="imageError"
                        :on-progress="imageProgress"
                        :on-success="(value, file) => imageSuccess(file)"
                         :action="action || (uploadData && uploadData.host && uploadData.host != '' ? uploadData.host : '')"
                        :data="uploadData"
                        :on-remove="imageRemove"
                        :show-upload-list="false"
                    >
                        <Button icon="ios-cloud-upload-outline" type="info" v-if="Type == 'edit' || Type == 'add' || Type == 'quote'" id="formuploadphoto">上传图片</Button>
                    </Upload>
                    <Button v-if="Type == 'edit' || Type == 'add' || Type == 'quote'" type="primary" @click="selectTemplate" style="margin-left: 10px">选择模板</Button>
                    <Button v-if="formData.photo && (Type == 'edit' || Type == 'add' || Type == 'quote')" type="success" @click="cropperFn" style="margin-left: 10px">裁剪图片</Button>
                </FormItem>
                <FormItem label="信息来源" prop="source" id="formsource">
                    <Input style="width: 300px" :disabled="Type == 'check' || Type == 'examine' || Type == 'send'" v-model.trim="formData.source" :maxlength="20" placeholder="请填写信息来源"></Input>
                </FormItem>
                <FormItem label="信息类型" id="formsubTy">
                    <Select v-model="formData.subType" style="width: 200px" placeholder="请选择信息类型" :disabled="Type == 'check' || Type == 'examine' || Type == 'send'">
                        <Option v-for="(item, index) in subTypeList" :value="item.value" :key="index">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem id="formdate">
                    <span slot="label" class="validate">公布时效:</span>
                    <DatePicker
                        :options="dateOption"
                        :disabled="Type == 'check' || Type == 'examine' || Type == 'send'"
                        :transfer="true"
                        type="datetimerange"
                        format="yyyy-MM-dd HH:mm:ss"
                        placement="bottom-end"
                        placeholder="请选择公布时效"
                        style="width: 300px"
                        v-model="formData.date"
                        @on-change="changePicker"
                    ></DatePicker>
                </FormItem>
                <FormItem label="发布终端" id="formchannel">
                    <span slot="label" class="validate">发布终端:</span>
                    <Checkbox
                        :disabled="Type == 'check' || Type == 'examine' || Type == 'send'"
                        @on-change="val => changeCheck(val, item)"
                        v-model="item.select"
                        v-for="(item, index) in channelList"
                        :key="index"
                        :label="item.label"
                    >
                        {{ item.value }}
                    </Checkbox>
                </FormItem>
                <FormItem label="发布范围" prop="city" id="formcity">
                    <span slot="label" class="validate">发布范围</span>
                    <CascaderMore :disabled="disabled" v-if="showCascader" style="width: 80%" :cityOper="cityOper"></CascaderMore>
                </FormItem>
                <!-- <FormItem v-if="Type == 'check' || Type =='examine' || Type == 'send'">
            <span slot="label">发布范围</span>
            <div class="cascader-div" v-if="itemList">
                <div class="item" v-for="(item,index) in itemList" :key="index">{{item}}</div>
            </div>
        </FormItem> -->
                <FormItem label="联系电话" id="formcontactMobile">
                    <Input :disabled="Type == 'check' || Type == 'examine' || Type == 'send'" style="width: 300px" v-model.trim="formData.contactMobile" :maxlength="20"></Input>
                </FormItem>
                <!-- <FormItem label="紧急状态" id="formpriority">
                    <Checkbox
                        :disabled="Type == 'check' || Type == 'examine' || Type == 'send'"
                        @on-change="val => changePriorityCheck(val, item)"
                        v-model="item.select"
                        v-for="(item, index) in priorityList"
                        :key="index"
                        :label="item.label"
                    >
                        {{ item.value }}
                    </Checkbox>
                </FormItem> -->
                <!-- <FormItem label="推送方式" >
                <Checkbox :disabled="Type == 'check' || Type =='examine'" v-model="formData.pushChannel" true-value="4" false-value="0">小程序订阅通知</Checkbox>
        </FormItem> -->
                <FormItem label="小程序订阅通知" id="formpush">
                    <CheckboxGroup v-model="formData.pushType" @on-change="changePushType">
                        <Checkbox :disabled="pushlishRoot == '1003' ? false : Type == 'check' || Type == 'examine' || Type == 'send'" label="1">我的用户</Checkbox>
                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的服务过的居民可收到推送信息。</span>
                        <br />
                        <Checkbox :disabled="pushlishRoot == '1003' ? false : Type == 'check' || Type == 'examine' || Type == 'send'" label="2">我的管理员</Checkbox>
                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的员工档案在职管理员可收到推送信息。</span>
                        <br />
                        <!-- <Checkbox :disabled="Type == 'check' || Type == 'examine'" label="3">我的畅联组</Checkbox>
                        <span v-if="formData.pushType && formData.pushType[0] == 3">
                            <Input style="width: 300px; margin-left: 16px" v-model="formData.groupNames" />
                            <Button :disabled="Type == 'check' || Type == 'examine'" type="primary" @click="groupStatus = true">选群</Button>
                        </span>
                        <br />
                        <Checkbox :disabled="Type == 'check' || Type == 'examine'" label="4" v-if="dimensionId == 2 || this.buttonRoot == '1003'">社区线上用户（原推送功能）</Checkbox>
                        <span v-if="dimensionId == 2 || this.buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的所有线上居民可收到推送信息。</span> -->
                         <Checkbox :disabled="pushlishRoot == '1003' ? false : Type == 'check' || Type == 'examine' || Type == 'send'" v-if="dimensionId == 2 || pushlishRoot == '1003'" label="5">社区注册用户</Checkbox>
                        <span v-if="dimensionId == 2 || pushlishRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知且 激活了社区（村）地址的居民用户可收到推送信息。</span>
                        <br />
                        <Checkbox :disabled="pushlishRoot == '1003' ? false : Type == 'check' || Type == 'examine' || Type == 'send'" v-if="dimensionId == 2 || pushlishRoot == '1003'" label="4">社区访问用户</Checkbox>
                        <span v-if="dimensionId == 2 || pushlishRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的所有访问过本社区的用户可收到推送信息</span>
                        
                       
                    </CheckboxGroup>
                </FormItem>
                <iframe
                    name="ueditor"
                    id="ueditor"
                    src="/mobileeditor/#/index?titlename=详细内容（必填）"
                    style="width: 100%; height: 700px; border: 0px"
                    @load="loadUeditor"
                    v-if="showCascader"
                ></iframe>
                <div v-if="Type == 'check' && formData.operationVo && formData.operationVo.createTime" style="margin-top: 60px">
                    <FormItem style="display: inline-block; width: 250px">
                        <span slot="label">拟制员工:</span>
                        <span>{{ formData.operationVo.createName || "" }}</span>
                    </FormItem>
                    <FormItem style="display: inline-block; width: 400px">
                        <span slot="label">拟制时间:</span>
                        <span>{{ this.$core.formatDate(new Date(formData.operationVo.createTime || ""), "yyyy-MM-dd hh:mm:ss") }}</span>
                    </FormItem>
                </div>
                <div v-if="Type == 'check' && formData.operationVo && formData.operationVo.reviewTime && formData.status != 1">
                    <FormItem style="display: inline-block; width: 250px">
                        <span slot="label">审核员工:</span>
                        <span>{{ formData.operationVo.reviewName || "" }}</span>
                    </FormItem>
                    <FormItem style="display: inline-block; width: 400px">
                        <span slot="label">审核时间:</span>
                        <span>{{ this.$core.formatDate(new Date(formData.operationVo.reviewTime || ""), "yyyy-MM-dd hh:mm:ss") }}</span>
                    </FormItem>
                </div>

                <div v-if="Type == 'check' && formData.operationVo && formData.operationVo.publishTime && formData.status != 3 && formData.status != 1 && formData.status != 2">
                    <FormItem style="display: inline-block; width: 250px">
                        <span slot="label">发布员工:</span>
                        <span>{{ formData.operationVo.publishName || "" }}</span>
                    </FormItem>
                    <FormItem style="display: inline-block; width: 400px">
                        <span slot="label">发布时间:</span>
                        <span>{{ this.$core.formatDate(new Date(formData.operationVo.publishTime || ""), "yyyy-MM-dd hh:mm:ss") }}</span>
                    </FormItem>
                </div>
                <div v-if="Type == 'check' && formData.status == '2'">
                    <FormItem style="display: inline-block; width: 250px">
                        <span slot="label">驳回员工:</span>
                        <span>{{ formData.dismissList[0].staffName || "" }}</span>
                    </FormItem>
                    <FormItem style="display: inline-block; width: 400px">
                        <span slot="label">驳回时间:</span>
                        <span>{{ this.$core.formatDate(new Date(formData.dismissList[0].gmtCreate || ""), "yyyy-MM-dd hh:mm:ss") }}</span>
                    </FormItem>
                </div>

                <FormItem v-if="Type == 'check' && formData.status == '2'" style="display: inline-block">
                    <span slot="label">驳回原因:</span>
                    <span style="color: red">{{ formData.dismissList[0].reason || "" }}</span>
                </FormItem>
            </Form>
            <!-- <Form
        :label-colon="true"
        :label-width="120"
        v-show="showMenu == 'moreSetting'"
      >
        <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=详细内容（必填）"
                    style="width: 100%; height: 700px; border: 0px"
                    @load="loadUeditor"
                    v-if="showCascader"
              ></iframe>
      </Form> -->
        </div>
        <LiefengModal :value="groupStatus" title="选择畅联组" :fullscreen="true" @input="changeGroupStatus">
            <template v-slot:contentarea>
                <CheckGroup :buttonRoot="buttonRoot" ref="checkGroup" @getGroup="getGroup"></CheckGroup>
            </template>
            <template v-slot:toolsbar>
                <!-- <Button type="info" @click="cancelGroup" style="margin-right:10px"> 取消 </Button> -->
                <Button type="primary" @click="saveGroup">保存</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import CascaderMore from "../components/CascaderMore.vue"
import LiefengModal from "@/components/LiefengModal"
// import Mymodel from "@/views/activity/Mymodel";
import { VueCropper } from "vue-cropper"
import CheckGroup from "./checkgroup.vue"
import Compressor from "compressorjs"
export default {
    components: { CascaderMore, VueCropper, LiefengModal, CheckGroup },
    props: {
        Type: "",
        formData: {
            type: Object,
            default: () => {
                return {}
            },
        },
        columnCode: "",
        showNum: 0,
        buttonRoot: "",
    },
    data() {
        return {
            dimensionId: "",
            groupStatus: false,
            groupNames: "",
            checkGroup: [],

            dateOption: {
                shortcuts: [
                    //公布时间配置项
                    {
                        text: "1天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1)
                            return [start, end]
                        },
                    },
                    {
                        text: "7天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
                            return [start, end]
                        },
                    },
                    {
                        text: "30天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
                            return [start, end]
                        },
                    },
                    {
                        text: "365天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 365)
                            return [start, end]
                        },
                    },
                    {
                        text: "五年",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 365 * 5)
                            return [start, end]
                        },
                    },
                ],
            },
            showMenu: "baseInfo",
            formDataValidate: {
                title: [{ required: true, message: "信息名称不能为空", trigger: "blur" }],
                source: [{ required: true, message: "数据来源不能为空", trigger: "blur" }],
                subType: [{ required: true, message: "信息类型不能为空", trigger: "change" }],
                date: [{ type: "array", max: 2, message: "公布时效不能为空", trigger: "change" }],
            },
            //信息类型数组
            subTypeList: [],
            channelList: [
                { label: "0", value: "全部", select: "" },
                { label: "1", value: "TV端", select: "" },
                // {label:'2',value:'PC端',select:''},
                { label: "3", value: "APP端", select: "" },
                { label: "4", value: "小程序", select: "" },
                // {label:'5',value:'公众号',select:''},
            ],
            priorityList: [
                { label: "0", value: "紧急", select: "" },
                { label: "1", value: "重要", select: "" },
            ],

            // 存放选择的区、街道、社区
            cityOper: {
                prov:[],
                city: [],
                region: [],
                stree: [],
                project: [],
            },
            showCascader: true,

            itemList: [],

            // 上传图片
            action: "",
            file: "",
            uploadData: {},
            imageName: "",
            imagePath: "",

            // 裁剪组件的基础配置option
            option: {
                img: "", // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: "jpg", // 裁剪生成图片的格式
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 600, // 默认生成截图框宽度
                autoCropHeight: 500, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [100, 69], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            cropperStatus: false,

            imgStatus: false,
            imgTemplateList: [],

            disabled: "",

            pushlishRoot:""
        }
    },
    created() {
        console.log('Type',this.Type);
        this.dimensionId = window.sessionStorage.getItem("dimensionId")
        sessionStorage.setItem("NODEENV", process.env.NODE_ENV)
        if(!this.pushlishRoot || this.pushlishRoot == ''){
            this.pushlishRoot = parent.vue.userButtonRoot.includes("1001") ? "1001" : parent.vue.userButtonRoot.includes("1002") ? "1002" : parent.vue.userButtonRoot.includes("1003") ? "1003" : ""
        }
    },
    methods: {
        changeGroupStatus(val) {
            this.groupStatus = val
        },
        cancelGroup() {
            this.groupStatus = false
        },
        saveGroup() {
            let list = this.$refs.checkGroup.rightList
            console.log(list)
            if (list.length == 0) {
                return this.$Message.warning({
                    content: "请选择群",
                    background: true,
                })
            }
            let groupName = []
            let groupId = []
            list.map(item => {
                groupName.push(item.groupName)
                groupId.push(item.groupId)
            })
            this.formData.groupNames = groupName.join(",")
            this.formData.channels = groupId.join(",")
            this.groupStatus = false
        },
        changePushType(data) {
            if (data.length == 2) {
                this.formData.pushType = [...data[1]]
            }
        },
        // 获取富文本框内容
        loadUeditor() {
            if (this.formData.content != "" && this.formData.content != null && this.formData.content != undefined) {
                var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
                v_Obj.setContent(this.formData.content) //写入编辑器富文本内容
            } else {
                // setTimeout(this.loadUeditor, 300);
            }
        },
        // 点击了选择时间的回调函数
        changePicker(val) {
            this.formData.date = val
        },
        // 点击推送方式的checkbox按钮
        changeCheck(val, item) {
            if (item.label == "0") {
                if (val) {
                    this.channelList.map(item => {
                        item.select = true
                    })
                } else {
                    this.channelList.map(item => {
                        item.select = false
                    })
                }
            } else {
                if (val) {
                    let data = []
                    this.channelList.map(item => {
                        if (item.label != "0") {
                            if (item.select) {
                                data.push(item)
                            }
                        }
                    })
                    if (data.length == this.channelList.length - 1) {
                        this.channelList.map(item => {
                            item.select = true
                        })
                    }
                } else {
                    this.channelList.map(item => {
                        if (item.label == "0") {
                            item.select = false
                        }
                    })
                }
            }

            let data = []
            this.channelList.map(item => {
                if (item.label == "0") {
                    this.formData.terminal = item.label
                } else {
                    if (item.select == true) {
                        data.push(item.label)
                    }
                }
            })
            if (data.length != 0) {
                if (data.length == this.channelList.length - 1) {
                    this.formData.terminal = "0"
                } else {
                    this.formData.terminal = data.join(",")
                }
            } else {
                this.formData.terminal = ""
            }
        },
        // 点击紧急情况checkbox按钮
        changePriorityCheck(val, item) {
            this.formData.priority = ""
            this.priorityList.map(items => {
                if (items.select == true) {
                    this.formData.priority += "1"
                } else {
                    this.formData.priority += "0"
                }
            })
        },
        // 切换菜单
        selectMenu(name) {
            this.showMenu = name
        },
        // 获取信息类型接口
        async getMeunParams() {
            await this.$get("/old/api/pc/menu/getMenuColumnParam", {
                columnCode: this.$route.query.menuId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                this.subTypeList = res
            })
        },
        // 上传之前
        async beforeUpload(file) {
             if (file.type == "image/gif") {
                const uploadData = await this.$core.uplaodImage(file)
                this.uploadData = uploadData

                this.imageName = this.uploadData.name
                this.imagePath = this.uploadData.host + this.uploadData.key
            } else {
                 return new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: 0.5, // 压缩质量，0.6 表示压缩到原图的 60%
                    convertSize: 200, // 需要压缩的起始大小，默认5M, 5 * 1000 * 1000
                    success: async result => {
                        console.log("压缩成功之后", result)
                        var res = new File([result], result.name, {
                            type: result.type,
                            lastModified: Date.now(),
                        })
                        const uploadData = await this.$core.uplaodImage(res)
                        this.uploadData = uploadData

                        this.imageName = this.uploadData.name
                        this.imagePath = this.uploadData.host + this.uploadData.key

                        console.log(this.uploadData,this.imageName,this.imagePath);
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                })
            })
            }
           
        },
        //图片上传成功
        imageSuccess(file) {
            if (file.status == "finished") {
                this.formData.photo = this.imagePath
                this.$Message["success"]({
                    background: true,
                    content: "图片上传成功！",
                })
            } else {
                this.$Message["error"]({
                    background: true,
                    content: "图片上传失败！",
                })
            }
        },
        // 图片格式错误
        imageError(file) {
            this.$Notice.warning({
                title: "上传文件格式错误！",
                desc: "文件 " + file.name + " 上传失败, 请上传jpg, jpeg, png或gif格式的图片！",
            })
        },
        // 图片上传进度
        imageProgress(event, file, fileList) {
            this.file = file
            event.target.onprogress = event => {
                let uploadPercent = parseFloat(((event.loaded / event.total) * 100).toFixed(2)) // 保留两位小数
                // 手动设置显示上传进度条 以及上传百分比
                this.file.showProgress = true
                this.file.percentage = uploadPercent
            }
        },
        // 图片移除
        imageRemove(file) {
            this.formData.photo = ""
            this.file = {}
        },

        //取消裁剪
        cropperCancel(status) {
            this.cropperStatus = status
        },
        //打开裁剪
        async cropperFn() {
            if (!this.uploadData.host) {
                await this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                    if (res.code == 200) {
                        let now = this.$core.randomNumber()
                        //防止文件名有.
                        let suffixArr = this.formData.photo.split(".")
                        let suffix = "." + suffixArr[suffixArr.length - 1]
                        this.action =
                            process.env.NODE_ENV == "dev" || process.env.NODE_ENV == "development" ? "http://liefeng.oss-cn-shenzhen.aliyuncs.com" : "https://liefeng.oss-cn-shenzhen.aliyuncs.com"
                        this.uploadData = {
                            name: now + suffix,
                            host: res.data.host,
                            key: res.data.key + now + suffix,
                            policy: res.data.encodedPolicy,
                            OSSAccessKeyId: res.data.accessId,
                            success_action_status: "200",
                            signature: res.data.postSignature,
                        }
                        this.imageName = now + suffix
                        this.imagePath = this.uploadData.host + this.uploadData.key
                    }
                })
            }

            let _this = this
            this.setAvatarBase64(this.formData.photo, base64 => {
                _this.option.img = base64
            })
            this.cropperStatus = true
        },
        //确定裁剪
        cropperFinish() {
            this.$refs.cropper.getCropBlob(data => {
                this.$refs.imgUpload.clearFiles()
                let formData = new FormData()
                formData.append("name", this.uploadData.name)
                formData.append("host", this.uploadData.host)
                formData.append("key", this.uploadData.key)
                formData.append("policy", this.uploadData.policy)
                formData.append("OSSAccessKeyId", this.uploadData.OSSAccessKeyId)
                formData.append("success_action_status", this.uploadData.success_action_status)
                formData.append("signature", this.uploadData.signature)
                formData.append("file", data)
                this.$post("https://liefeng.oss-cn-shenzhen.aliyuncs.com", formData, {
                    "Content-Type": "multipart/form-data",
                }).then(res => {
                    this.$Message.success({
                        background: true,
                        content: "裁剪完成",
                    })
                    this.formData.photo = ""
                    this.$nextTick(() => {
                        this.formData.photo = this.imagePath + "?" + new Date().getTime()
                        this.cropperCancel(false)
                    })
                })
            })
        },
        // 设置图片base64
        setAvatarBase64(src, callback) {
            let _this = this
            let image = new Image()
            // 处理缓存
            image.src = src + "?v=" + Math.random()
            // 支持跨域图片
            image.crossOrigin = "*"
            image.onload = function () {
                let base64 = _this.transBase64FromImage(image)
                callback && callback(base64)
            }
        },
        // 将网络图片转换成base64格式
        transBase64FromImage(image) {
            let canvas = document.createElement("canvas")
            canvas.width = image.width
            canvas.height = image.height
            let ctx = canvas.getContext("2d")
            ctx.drawImage(image, 0, 0, image.width, image.height)
            // 可选其他值 image/jpeg
            return canvas.toDataURL("image/png")
        },

        selectTemplate() {
            if (this.imgTemplateList.length == 0) {
                this.$get("/datamsg/api/pc/fileUpload/selectFileUploadPage", {
                    groupId: 10000,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    fileType: "image",
                    page: 1,
                    pageSize: 100,
                }).then(res => {
                    if (res.code == 200) {
                        this.imgTemplateList = res.dataList
                    }
                })
            }

            this.imgStatus = true
        },

        changeImgStatus(val) {
            this.imgStatus = val
        },
        selectImg(path) {
            this.formData.photo = path
            this.imgStatus = false
        },
    },
    watch: {
        async showNum(val) {
            this.$refs.checkGroup.rightList = []
            this.showMenu = "baseInfo"
            this.itemList = []
            this.cityOper = {
                prov:[],
                city: [],
                region: [],
                stree: [],
                project: [],
            }
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            await this.getMeunParams()

            if (this.Type == "add") {
                this.disabled = false
                this.formData.date = []
                const end = new Date()
                const start = new Date()
                end.setTime(start.getTime() + 3600 * 1000 * 24 * 365 * 5)
                this.formData.date.push(start, end)
                this.channelList.map(item => {
                    item.select = true
                })
                this.priorityList.map(item => {
                    item.select = false
                })
                if (!this.formData.source || this.formData.source == "") {
                    this.formData.source = parent.vue.loginInfo.userinfo.orgName || ""
                }
                if (!this.formData.subType || this.formData.subType == "") {
                    if (this.subTypeList && this.subTypeList.length) {
                        this.formData.subType = this.subTypeList[0].value
                    }
                }
                if (!this.formData.content || this.formData.content == undefined) {
                    this.formData.content = ""
                }
            } else if (this.Type == "edit" || this.Type == "check" || this.Type == "examine" || this.Type == "send" || this.Type == "quote") {
                if (this.Type == "edit" || this.Type == "send") {
                    if (this.formData.pushChannelVo && this.formData.pushChannelVo.pushType == 3 && this.formData.groupList && this.formData.groupList.length != 0) {
                        this.$refs.checkGroup.rightList = this.formData.groupList
                    }
                }
                if (this.Type == "edit" || this.Type == "quote") this.disabled = false
                else this.disabled = true
                if (this.Type == "quote") {
                    delete this.formData.operationVo
                    delete this.formData.dismissList
                    delete this.formData.dataScopeList
                    delete this.formData.id
                    delete this.formData.gmtCreate
                    if (this.subTypeList && this.subTypeList.length) this.formData.subType = this.subTypeList[0].value
                    else this.formData.subType = ""
                    this.formData.startTime = this.$core.formatDate(new Date(Date.now()), "yyyy-MM-dd hh:mm:ss")
                    this.formData.endTime = this.$core.formatDate(new Date(Date.now() + 3600 * 1000 * 24 * 365 * 5), "yyyy-MM-dd hh:mm:ss")
                    this.formData.terminal = "0"
                }
                if (this.formData.startTime && this.formData.startTime != "" && this.formData.endTime && this.formData.endTime != "") {
                    this.formData.date = []
                    this.formData.date.push(this.formData.startTime, this.formData.endTime)
                }
                if (this.formData.priority && this.formData.priority != "") {
                    if (this.formData.priority == "11")
                        this.priorityList.map(item => {
                            item.select = true
                        })
                    else if (this.formData.priority == "01")
                        this.priorityList.map(item => {
                            if (item.label == "1") item.select = true
                        })
                    else if (this.formData.priority == "10")
                        this.priorityList.map(item => {
                            if (item.label == "0") item.select = true
                        })
                    else if (this.formData.priority == "00")
                        this.priorityList.map(item => {
                            item.select = false
                        })
                }
                if (this.formData.terminal && this.formData.terminal != "") {
                    if (this.formData.terminal == "0") {
                        this.channelList.map(item => {
                            item.select = true
                        })
                    } else {
                        this.channelList.map(item => {
                            if (this.formData.terminal.includes(item.label)) item.select = true
                            else item.select = false
                        })
                    }
                }
                if (this.Type != "quote") {
                    if (this.formData.dataScopeList.length == 1) {
                        //回显发布范围
                        this.formData.dataScopeList.map(item => {
                            let arr = item.split("-")
                            switch (arr.length) {
                                case 1:
                                    this.cityOper.prov = [item]
                                    break
                                case 2:
                                    this.cityOper.prov = [arr[0]]
                                    this.cityOper.city = [item]
                                    break
                                case 3:
                                    this.cityOper.prov = [arr[0]]
                                    this.cityOper.city = [arr[0] + "-" + arr[1]]
                                    this.cityOper.region = [item]
                                    break
                                case 4:
                                    this.cityOper.prov = [arr[0]]
                                    this.cityOper.city = [arr[0] + "-" + arr[1]]
                                    this.cityOper.region = [arr[0] + "-" + arr[1] + "-" + arr[2]]
                                    this.cityOper.stree = [item]
                                    break
                                case 5:
                                    this.cityOper.prov = [arr[0]]
                                    this.cityOper.city = [arr[0] + "-" + arr[1]]
                                    this.cityOper.region = [arr[0] + "-" + arr[1] + "-" + arr[2]]
                                    this.cityOper.stree = [arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]]
                                    this.cityOper.project = [item]
                                    break
                            }
                        })
                    } else if (this.formData.dataScopeList.length > 1) {
                        this.formData.dataScopeList.map(item => {
                            let arr = item.split("-")
                            switch (arr.length) {
                                case 1:
                                    this.cityOper.prov.push(item)
                                case 2:
                                    this.cityOper.prov.push(arr[0])
                                    this.cityOper.city.push(item)
                                    break
                                case 3:
                                    this.cityOper.prov.push(arr[0])
                                    this.cityOper.city.push(arr[0] + "-" + arr[1])
                                    this.cityOper.region.push(item)
                                    break
                                case 4:
                                    this.cityOper.prov.push(arr[0])
                                    this.cityOper.city.push(arr[0] + "-" + arr[1])
                                    this.cityOper.region.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                                    this.cityOper.stree.push(item)
                                    break
                                case 5:
                                    this.cityOper.prov.push(arr[0])
                                    this.cityOper.city.push(arr[0] + "-" + arr[1])
                                    this.cityOper.region.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                                    this.cityOper.stree.push(arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3])
                                    this.cityOper.project.push(item)
                                    break
                            }
                        })

                        this.cityOper.prov = Array.from(new Set(this.cityOper.prov))
                        this.cityOper.city = Array.from(new Set(this.cityOper.city))
                        this.cityOper.region = Array.from(new Set(this.cityOper.region))
                        this.cityOper.stree = Array.from(new Set(this.cityOper.stree))
                        this.cityOper.project = Array.from(new Set(this.cityOper.project))
                    }
                }
                if (this.formData.releaseScope && this.formData.releaseScope != "") {
                    this.itemList = this.formData.releaseScope.split(",")
                }
            }
        },
        buttonRoot:{
            handler(val){
                if(val) this.pushlishRoot = val
            }
        }
    },
}
</script>

<style lang="less" scoped>
.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 123px);
    /deep/.menu {
        height: 100%;
        .ivu-menu {
            height: 100%;
            width: 220px !important;
        }
    }
    .content {
        overflow: auto;
        flex: 1;
        height: 100%;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.cascader-div {
    width: 200px;
    .item {
        cursor: pointer;
        user-select: none;
        margin: 5px 0;
        padding: 0 10px;
        border: 1px solid #c2e7b0;
        color: #67c23a;
    }
}
// 截图
.cropper-content {
    .cropper {
        width: 580px;
        height: 428px;
    }
}
.imgbox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: wrap;
    .imgitem {
        margin: 5px;
        width: 270px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
            box-shadow: 0 0 8px rgb(233, 61, 61);
        }
        img {
            width: 100%;
        }
    }
}
</style>