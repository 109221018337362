<template>
    <div>
        <Select
            id="cityOpercity"
            v-model="cityOper.prov"
            transfer
            style="width: 20%"
            :max-tag-count="2"
            placeholder="请选择省"
            multiple
            filterable
            @on-change="changeProv"
            :disabled="disabled"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in proList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
         <Select
            id="cityOpercity"
            v-model="cityOper.city"
            transfer
            style="width: 20%"
            :max-tag-count="2"
            placeholder="请选择市"
            multiple
            filterable
            @on-change="changeCity"
            :disabled="cityDisabled"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in cityList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            id="cityOperregion"
            v-model="cityOper.region"
            transfer
            style="width: 20%"
            :max-tag-count="2"
            placeholder="请选择区"
            :disabled="regionDisabled"
            multiple
            filterable
            @on-change="changeRegion"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in regionList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            id="cityOperstree"
            v-model="cityOper.stree"
            transfer
            style="width: 20%"
            :max-tag-count="2"
            placeholder="请选择街道/乡镇"
            :disabled="streeDisabled"
            multiple
            filterable
            @on-change="changeStree"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in streeList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            id="cityOperproject"
            v-model="cityOper.project"
            transfer
            style="width: 20%"
            :max-tag-count="1"
            placeholder="请选择社区"
            :disabled="projectDisabled"
            multiple
            filterable
          >
            <Option
              :value="item.value"
              v-for="(item, index) in projectList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
    </div>
</template>

<script>
export default {
    props:['cityOper','disabled'],
    data(){
        return{
            dataScopeIdList: [], //发布范围列表
            // city: [],
            // region: [],
            // stree: [],
            // project: [],
            proList:[],
            cityList: [],
            regionList: [],
            streeList: [],
            projectList: [],
            regionDisabled: true,
            streeDisabled: true,
            projectDisabled: true,
            cityDisabled:false,
        }
    },
    created(){
        this.getPulishData("CN", "proList", "prov");
         this.cityDisabled = true
         this.regionDisabled = true
         this.streeDisabled =true
         this.projectDisabled =true
    
    },
    methods:{
        // 省的选择
        changeProv(code) {
            if (code.length >= 2) {
                this.cityList = []
                this.regionList = [];
                this.streeList = [];
                this.projectList = [];
                this.cityOper.city = []
                this.cityOper.region = [];
                this.cityOper.stree = [];
                this.cityOper.project = [];
                this.cityDisabled = true
                this.regionDisabled = true;
                this.streeDisabled = true;
                this.projectDisabled = true;
            } else if (code.length == 1) {
                if(!this.disabled)
                this.cityDisabled = false;
                let arrCode = code[code.length - 1].split("-");
                this.getPulishData(arrCode[arrCode.length - 1], "cityList", "city");
            } else {
                this.cityList = []
                this.regionList = [];
                this.streeList = [];
                this.projectList = [];
                this.cityOper.region = [];
                this.cityOper.stree = [];
                this.cityOper.project = [];
            }
        },
        // 城市选择
        changeCity(code) {
            if (code.length >= 2) {
                this.regionList = [];
                this.streeList = [];
                this.projectList = [];
                this.cityOper.region = [];
                this.cityOper.stree = [];
                this.cityOper.project = [];
                this.regionDisabled = true;
                this.streeDisabled = true;
                this.projectDisabled = true;
            } else if (code.length == 1) {
                if(!this.disabled)
                this.regionDisabled = false;
                let arrCode = code[code.length - 1].split("-");
                this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region");
            } else {
                this.regionList = [];
                this.streeList = [];
                this.projectList = [];
                this.cityOper.region = [];
                this.cityOper.stree = [];
                this.cityOper.project = [];
            }
        },
        // 区选择
        changeRegion(code) {
            if (code.length >= 2) {
                this.streeList = [];
                this.projectList = [];
                this.cityOper.stree = [];
                this.cityOper.project = [];
                this.streeDisabled = true;
                this.projectDisabled = true;
            } else if (code.length == 1) {
                if(!this.disabled)
                this.streeDisabled = false;
                let arrCode = code[code.length - 1].split("-");
                this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree");
            } else {
                this.streeList = [];
                this.projectList = [];
                this.cityOper.stree = [];
                this.cityOper.project = [];
            }
        },
        // 街道选择
        changeStree(code) {
            if (code.length >= 2) {
                this.projectList = [];
                this.project = [];
                this.projectDisabled = true;
            } else if (code.length == 1) {
                if(!this.disabled)
                this.projectDisabled = false;
                let arrCode = code[code.length - 1].split("-");
                this.getPulishData(
                arrCode[arrCode.length - 1],
                "projectList",
                "project"
                );
            } else {
                this.projectList = [];
                this.project = [];
            }
        },
        // 获取发布范围列表
        getPulishData(code, list, model) {
            this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then((res) => {
                if (res.code == 200) {
                    this[list] = res.dataList.map((item) => {
                        return {
                        value: item.orgPath,
                        label: item.orgName,
                        orgLevel: item.orgLevel,
                        };
                    });
                    if (res.dataList.length == "1") {
                        this.cityOper[model] = [res.dataList[0].orgPath];
                    }

                }
            });
        },
        
    },
    watch:{
        disabled(val){
            console.log
            if(val){
                // this.gionDisabled = true,
                // this.streeDisabled = true,
                // this.projectDisabled = true,
                // this.cityDisabled = true
            }
        }
    }
}
</script>

<style>

</style>